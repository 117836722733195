import '@styles/main.css'
import { global } from './global'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Swup from 'swup'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupPreloadPlugin from '@swup/preload-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import SwupScriptsPlugin from '@swup/scripts-plugin'
import { scrollToLocationHash } from '@scripts/helpers'
import { pageTransitionIn, pageTransitionOut } from './animation/swup-page-transition'
import { runModules } from './helpers'
import { runAnimations } from './helpers'
import { preloader } from './animation/preloader'

gsap.registerPlugin(ScrollTrigger)

global()

runModules('init')
preloader()

window.addEventListener(
  'preloaderFinished',
  () => {
    runAnimations('init')
  },
  { once: true }
)

const swup = new Swup({
  containers: ['#main'],
  plugins: [
    new SwupHeadPlugin({ persistTags: 'style' }),
    new SwupBodyClassPlugin(),
    new SwupPreloadPlugin(),
    new SwupScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: {
        betweenPages: false,
        samePageWithHash: false,
        samePage: true
      },
      scrollFriction: 0.00001,
      scrollAcceleration: 1
    }),
    new SwupScriptsPlugin({
      head: false,
      body: true
    })
  ],
  animateHistoryBrowsing: true,
  skipPopStateHandling: event => event.state?.source !== 'swup'
})

window.swup = swup

swup.hooks.on('animation:out:start', async () => {
  await pageTransitionIn()
})

swup.hooks.on('animation:out:end', async () => {
  runModules('destroy')
  runAnimations('destroy')
})

swup.hooks.on('animation:in:start', visit => {
  // console.log('🚀 ~ swup.hooks.on ~ visit:', visit)
  // if (window?.lenis?.scrollTo) {
  //   setTimeout(() => {
  //     window.lenis.scrollTo(0, { immediate: true, force: true })
  //   }, 1)
  // } else {
  //   window.scrollTo({ top: 0, behavior: 'instant' })
  // }

  window.scrollTo({ top: 0, behavior: 'instant' })

  runModules('init')

  const facetElements = document.querySelectorAll(['.facetwp-template', '.facetwp-facet'])
  if (facetElements.length > 0) {
    if (typeof FWP !== 'undefined') {
      FWP.init()
      FWP.refresh()
      FWP.fetchData()
      FWP.parseFacets()
      // FWP.reset()
    }

    // Prevent FacetWP updating history state, which interferes with Swup -> https://facetwp.com/help-center/developers/the-facetwp-url/#disable-facetwps-query-string
    document.addEventListener('facetwp-refresh', function () {
      if (!FWP.loaded) {
        FWP.setHash = function () {
          /* empty */
        }
      }
    })
  }

  ScrollTrigger.refresh()
})

swup.hooks.replace('link:self', visit => swup.navigate(visit.to.url))

swup.hooks.on('animation:in:end', async visit => {
  scrollToLocationHash()
  await pageTransitionOut(visit)
  runAnimations('init')
})
